import { all } from 'redux-saga/effects';
import {
  getSectorsSaga,
  getCompanySizesSaga,
  getAreasSaga,
  getLevelsSaga,
  getCourseTypesSaga,
  getCourseStatusSaga,
  getJobIntegrationsSaga,
} from './jobs';

function* rootSaga() {
  yield all([
    getSectorsSaga(),
    getCompanySizesSaga(),
    getAreasSaga(),
    getLevelsSaga(),
    getCourseTypesSaga(),
    getCourseStatusSaga(),
    getJobIntegrationsSaga(),
  ]);
}

export default rootSaga;
