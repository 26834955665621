export const PAGE_SETTINGS = {
  QTY_CITY_SEARCH: 10,
  TIMEOUT_CITY_SEARCH: 500,
  PAGE_SIZE: 10,
  TIMEOUT_JOB_SEARCH: 800,
  QTY_LANGUAGE_SEARCH: 10,
  TIMEOUT_LANGUAGE_SEARCH: 500,
}

export const JOB_SETTINGS = {
  MAX_EXPERIENCE_SECTORS: 5,
  MAX_LEVELS: 2,
  MAX_CITIES: 5,
}

export const CANDIDATE_SETTINGS = {
  MAX_EXPERIENCE_SECTORS: 5,
  MAX_LEVELS: 2,
}

export const MAX_MIDIA_SIZE = 800; // 500MB

export const MAX_MIDIA_XLSX = 100;  // 100MB

export const MAX_IMAGE_SIZE = 5;  // 5MB

export const TIMEOUT_UPLOAD_MIDIA = 300000;  // 5min

export const AccountRoles = {
  USER: 'USER',
  ADMIN: 'ADMIN',
}

export const CONTENT_TYPE = {
  WEBSERIE: 'WEBSERIE',
  CAREER_CONTENT: 'CAREER_CONTENT',
}
