import { takeLatest, retry, put } from 'redux-saga/effects';
import {
  setCompanySectors,
  setJobAreas,
  setJobLevels,
  setCompanySizes,
  setCourseTypes,
  setCourseStatus,
  setJobIntegrations,
  Types as CombosTypes,
} from '../ducks/jobs';
import APIService from '../../services';

function* getSectors(action) {
  try {
    const { token } = action;
    const SECOND = 1000;
    const response = yield retry(3, 1 * SECOND, APIService.getCompanySectors, {
      token,
    });
    if (!response.data.success) {
      throw new Error(response.data?.message);
    }
    const list = response.data?.result;
    yield put(setCompanySectors(list));
  } catch (error) {
    console.warn('[Jobs] Error - ', error.message);
    yield put(setCompanySectors([]));
  }
}

function* getCompanySizes(action) {
  try {
    const { token } = action;
    const SECOND = 1000;
    const response = yield retry(3, 1 * SECOND, APIService.getCompanySizes, {
      token,
    });
    if (!response.data.success) {
      throw new Error(response.data?.message);
    }
    const list = response.data?.result;
    yield put(setCompanySizes(list));
  } catch (error) {
    console.warn('[Jobs] Error - ', error.message);
    yield put(setCompanySizes([]));
  }
}

function* getAreas(action) {
  try {
    const { token } = action;
    const SECOND = 1000;
    const response = yield retry(3, 1 * SECOND, APIService.getJobAreas, {
      token,
    });
    if (!response.data.success) {
      throw new Error(response.data?.message);
    }
    const list = response.data?.result;
    yield put(setJobAreas(list));
  } catch (error) {
    console.warn('[Jobs] Error - ', error.message);
    yield put(setJobAreas([]));
  }
}

function* getLevels(action) {
  try {
    const { token } = action;
    const SECOND = 1000;
    const response = yield retry(3, 1 * SECOND, APIService.getJobLevels, {
      token,
    });
    if (!response.data.success) {
      throw new Error(response.data?.message);
    }
    const list = response.data?.result;
    yield put(setJobLevels(list));
  } catch (error) {
    console.warn('[Jobs] Error - ', error.message);
    yield put(setJobLevels([]));
  }
}

function* getCourseTypes(action) {
  try {
    const { token } = action;
    const SECOND = 1000;
    const response = yield retry(3, 1 * SECOND, APIService.getCourseTypes, {
      token,
    });
    if (!response.data.success) {
      throw new Error(response.data?.message);
    }
    const list = response.data?.result;
    yield put(setCourseTypes(list));
  } catch (error) {
    console.warn('[Jobs] Error - ', error.message);
    yield put(setCourseTypes([]));
  }
}

function* getCourseStatus(action) {
  try {
    const { token } = action;
    const SECOND = 1000;
    const response = yield retry(3, 1 * SECOND, APIService.getCourseStatus, {
      token,
    });
    if (!response.data.success) {
      throw new Error(response.data?.message);
    }
    const list = response.data?.result;
    yield put(setCourseStatus(list));
  } catch (error) {
    console.warn('[Jobs] Error - ', error.message);
    yield put(setCourseStatus([]));
  }
}

function* getJobIntegrations(action) {
  try {
    const { token } = action;
    const SECOND = 1000;
    const response = yield retry(3, 1 * SECOND, APIService.getJobIntegrations, {
      token,
    });
    if (!response.data.success) {
      throw new Error(response.data?.message);
    }
    const list = response.data?.result;
    yield put(setJobIntegrations(list));
  } catch (error) {
    console.warn('[Jobs] Error - ', error.message);
    yield put(setJobIntegrations([]));
  }
}

export function* getSectorsSaga() {
  yield takeLatest(CombosTypes.FETCH_SECTORS, getSectors);
}

export function* getCompanySizesSaga() {
  yield takeLatest(CombosTypes.FETCH_COMPANY_SIZES, getCompanySizes);
}

export function* getAreasSaga() {
  yield takeLatest(CombosTypes.FETCH_AREAS, getAreas);
}

export function* getLevelsSaga() {
  yield takeLatest(CombosTypes.FETCH_LEVELS, getLevels);
}

export function* getCourseTypesSaga() {
  yield takeLatest(CombosTypes.FETCH_COURSE_TYPES, getCourseTypes);
}

export function* getCourseStatusSaga() {
  yield takeLatest(CombosTypes.FETCH_COURSE_STATUS, getCourseStatus);
}

export function* getJobIntegrationsSaga() {
  yield takeLatest(CombosTypes.FETCH_JOB_INTEGRATIONS, getJobIntegrations);
}
