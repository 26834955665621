export const Types = {
  SET_ONBOARDING: 'APP/SET_ONBOARDING',
  SET_LOADING: 'APP/SET_LOADING',
  SET_VIDEO_RUNNING: 'APP/SET_VIDEO_RUNNING',
  TOGGLE_SIDEBAR: 'APP/TOGGLE_SIDEBAR',
  SET_TOAST: 'APP/SET_TOAST',
};

const initialState = {
  doneOnboarding: false,
  token: '123',
  loadingPopUp: false,
  videoRunningId: null,
  sidebarShow: 'responsive',
  toasts: [],
};

export const setOnboarding = (done) => ({
  type: Types.SET_ONBOARDING,
  done,
});

export const setLoading = (loadingPopUp) => ({
  type: Types.SET_LOADING,
  loadingPopUp,
});

export const setVideoRunning = (videoId) => ({
  type: Types.SET_VIDEO_RUNNING,
  videoId,
});

export const toggleSidebar = (sidebarShow) => ({
  type: Types.TOGGLE_SIDEBAR,
  sidebarShow,
});

export const setToast = (toast) => ({
  type: Types.SET_TOAST,
  toast,
});


const OnboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_ONBOARDING:
      return { ...state, doneOnboarding: action.done };
    case Types.SET_LOADING:
      return { ...state, loadingPopUp: action.loadingPopUp };
    case Types.SET_VIDEO_RUNNING:
      return { ...state, videoId: action.videoId };
    case Types.TOGGLE_SIDEBAR:
      return { ...state, sidebarShow: action.sidebarShow };
    case Types.SET_TOAST:
      return { ...state, toasts: [...state.toasts, action.toast] };
    default:
      return state;
  }
};

export default OnboardingReducer;
