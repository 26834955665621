export const Types = {
  SET_USER_DATA: 'USER/SET_USER_DATA',
  LOGOUT: 'USER/LOGOUT',
};

const initialState = {
  token: sessionStorage.getItem('token'),
  user: null,
  isLogged: sessionStorage.getItem('isLogged') === 'true',
  loading: false,
  updateLoading: false,
  updateError: null,
};

export const setUserData = ({ userData, token }) => ({
  type: Types.SET_USER_DATA,
  userData,
  token,
});

export const logout = () => {
  sessionStorage.clear();
  return {
    type: Types.LOGOUT,
  }
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_USER_DATA:
      return {
        ...state,
        loading: false,
        error: null,
        user: { ...state.user, ...action.userData },
        token: action.token,
        isLogged: true,
      };
    case Types.LOGOUT:
      return { ...initialState, isLogged: false, token: null };
    default:
      return state;
  }
};

export default UserReducer;
