import axios from 'axios';
import env from 'react-dotenv';
import { TIMEOUT_UPLOAD_MIDIA } from 'src/utils/constants';
import isNullOrEmpty from 'src/utils/isNullOrEmpty';

const { API_URL, TIMEOUT_DEFAULT } = env;

function headers({ token }) {
  return {
    Authorization: token ? `Bearer ${token}` : null,
  };
}

const createInstance = (params, responseType, timeout = TIMEOUT_DEFAULT) =>
  axios.create({
    baseURL: params.baseURL || API_URL,
    headers: headers(params.headers || {}),
    timeout,
    responseType,
    validateStatus: function (status) {
      return status >= 200 && status < 400;
    },
  });

class APIService {
  // Account
  static login({ username, password }) {
    return createInstance({}).post('/v1/account/login', {
      username,
      password,
    });
  }

  static listUsers({ token, searchText, companyId, pageNumber, pageSize }) {
    let url = `/v1/account/list?page=${pageNumber}&pageSize=${pageSize}`;
    if (!isNullOrEmpty(companyId)) {
      url += `&companyId=${companyId}`;
    }
    if (!isNullOrEmpty(searchText)) {
      url += `&search=${searchText}`;
    }
    return createInstance({ headers: { token } }).get(url);
  }

  static postUser({ token, payload }) {
    return createInstance({ headers: { token } }).post(
      '/v1/account/create',
      payload,
    );
  }

  static getUser({ token, id }) {
    return createInstance({ headers: { token } }).get(`/v1/account/get/${id}`);
  }

  static deleteUser({ token, id }) {
    return createInstance({ headers: { token } }).delete(`/v1/account/${id}`);
  }

  static postUserImport({ token, companyId, file }) {
    const body = new FormData();
    body.append('file', file);

    return createInstance({ headers: { token, "Content-Type": "multipart/form-data" } }, undefined, 60000).post(
      `/v1/account/import?companyId=${companyId}`,
      body,
    );
  }

  // Categorias
  static listCategoriesEnabled({ token, pageNumber, pageSize, sectionOnly }) {
    let url = `/v1/category/list?page=${pageNumber}&pageSize=${pageSize}`;
    if (!isNullOrEmpty(sectionOnly)) {
      url += `&sectionOnly=${sectionOnly}`;
    }
    return createInstance({ headers: { token } }).get(url);
  }

  static listCategories({ token, pageNumber, pageSize, enabled, sectionOnly }) {
    let url = `/v1/category/list/all?page=${pageNumber}&pageSize=${pageSize}`;
    if (!isNullOrEmpty(enabled)) {
      url += `&enabled=${enabled}`;
    }
    if (!isNullOrEmpty(sectionOnly)) {
      url += `&sectionOnly=${sectionOnly}`;
    }
    return createInstance({ headers: { token } }).get(url);
  }

  static getCategory({ token, id }) {
    return createInstance({ headers: { token } }).get(`/v1/category/get/${id}`);
  }

  static postCategory({ token, payload }) {
    return createInstance({ headers: { token } }).post(
      '/v1/category',
      payload,
    );
  }

  static putCategory({ token, id, payload }) {
    return createInstance({ headers: { token } }).put(
      `/v1/category/${id}`,
      payload,
    );
  }

  static deleteCategory({ token, id }) {
    return createInstance({ headers: { token } }).delete(`/v1/category/${id}`);
  }

  // Section
  static listSections({ token, pageNumber, pageSize, enabled }) {
    let url = `/v1/section/list/all?page=${pageNumber}&pageSize=${pageSize}`;
    if (!isNullOrEmpty(enabled)) {
      url += `&enabled=${enabled}`;
    }
    return createInstance({ headers: { token } }).get(url);
  }
  
  static getSection({ token, id }) {
    return createInstance({ headers: { token } }).get(`/v1/section/get/admin/${id}`);
  }
  
  static postSection({ token, payload }) {
    return createInstance({ headers: { token } }).post(
      '/v1/section',
      payload,
    );
  }
  
  static putSection({ token, id, payload }) {
    return createInstance({ headers: { token } }).put(
      `/v1/section/${id}`,
      payload,
    );
  }
  
  static deleteSection({ token, id }) {
    return createInstance({ headers: { token } }).delete(`/v1/section/${id}`);
  }

  // Webseries
  static listWebseries({ token, enabled, highlighted, categories, pageNumber, pageSize }) {
    let url = `/v1/webserie/list/all?page=${pageNumber}&pageSize=${pageSize}`;
    if (!isNullOrEmpty(enabled)) {
      url += `&enabled=${enabled}`;
    }
    if (!isNullOrEmpty(highlighted)) {
      url += `&highlighted=${highlighted}`;
    }
    return createInstance({ headers: { token } }).get(url);
  }

  static getWebserie({ token, id }) {
    return createInstance({ headers: { token } }).get(`/v1/webserie/get/${id}`);
  }

  static deleteWebserie({ token, id }) {
    return createInstance({ headers: { token } }).delete(`/v1/webserie/${id}`);
  }

  static putWebserie({ token, id, payload }) {
    const body = new FormData();

    Object.keys(payload).map(key => body.append(key, payload[key]));

    return createInstance({ headers: { token, "Content-Type": "multipart/form-data" } }, undefined, TIMEOUT_UPLOAD_MIDIA).put(
      `/v1/webserie/${id}`,
      body,
    );
  }

  static postWebserie({ token, payload }) {
    const body = new FormData();

    Object.keys(payload).map(key => body.append(key, payload[key]));

    return createInstance({ headers: { token, "Content-Type": "multipart/form-data" } }, undefined, TIMEOUT_UPLOAD_MIDIA).post(
      '/v1/webserie',
      body,
    );
  }

  static patchWebserieOrder({ token, payload }) {
    return createInstance({ headers: { token } }).patch('/v1/webserie/order', payload);
  }

  static getWebserieEpisode({ token, id }) {
    return createInstance({ headers: { token } }).get(`/v1/webserie/episode/get/${id}`);
  }

  static deleteWebserieEpisode({ token, id }) {
    return createInstance({ headers: { token } }).delete(`/v1/webserie/episode/${id}`);
  }

  static putWebserieEpisode({ token, id, payload, onUploadProgress }) {
    const body = new FormData();

    Object.keys(payload).map(key => body.append(key, payload[key]));

    return createInstance({ headers: { token, "Content-Type": "multipart/form-data" } }, undefined, TIMEOUT_UPLOAD_MIDIA).put(
      `/v1/webserie/episode/${id}`,
      body,
      { onUploadProgress }
    );
  }

  static postWebserieEpisode({ token, webserieId, payload, onUploadProgress }) {
    const body = new FormData();

    Object.keys(payload).map(key => body.append(key, payload[key]));

    return createInstance({ headers: { token, "Content-Type": "multipart/form-data" } }, undefined, TIMEOUT_UPLOAD_MIDIA).post(
      `/v1/webserie/${webserieId}/episode`,
      body,
      { onUploadProgress }
    );
  }

  static getWebserieAdditional({ token, id }) {
    return createInstance({ headers: { token } }).get(`/v1/webserie/additional/get/${id}`);
  }

  static deleteWebserieAdditional({ token, id }) {
    return createInstance({ headers: { token } }).delete(`/v1/webserie/additional/${id}`);
  }

  static postWebserieAdditional({ token, webserieId, payload }) {
    const body = new FormData();

    Object.keys(payload).map(key => body.append(key, payload[key]));

    return createInstance({ headers: { token, "Content-Type": "multipart/form-data" } }, undefined, TIMEOUT_UPLOAD_MIDIA).post(
      `/v1/webserie/${webserieId}/additional`,
      body,
    );
  }

  static putWebserieAdditional({ token, id, payload }) {
    const body = new FormData();

    Object.keys(payload).map(key => body.append(key, payload[key]));

    return createInstance({ headers: { token, "Content-Type": "multipart/form-data" } }, undefined, TIMEOUT_UPLOAD_MIDIA).put(
      `/v1/webserie/additional/${id}`,
      body,
    );
  }

  static postWebserieContentAdditional({ token, id, payload }) {
    return createInstance({ headers: { token } }).post(`/v1/webserie/${id}/content/additional`, payload);
  }

  static getWebserieEpisodeAdditional({ token, id }) {
    return createInstance({ headers: { token } }).get(`/v1/webserie/episode/additional/${id}`);
  }

  static deleteWebserieEpisodeAdditional({ token, id }) {
    return createInstance({ headers: { token } }).delete(`/v1/webserie/episode/additional/${id}`);
  }

  static postWebserieEpisodeAdditional({ token, webserieEpisodeId, payload }) {
    const body = new FormData();

    Object.keys(payload).map(key => body.append(key, payload[key]));

    return createInstance({ headers: { token, "Content-Type": "multipart/form-data" } }, undefined, TIMEOUT_UPLOAD_MIDIA).post(
      `/v1/webserie/episode/${webserieEpisodeId}/additional`,
      body,
    );
  }

  static putWebserieEpisodeAdditional({ token, id, payload }) {
    const body = new FormData();

    Object.keys(payload).map(key => body.append(key, payload[key]));

    return createInstance({ headers: { token, "Content-Type": "multipart/form-data" } }, undefined, TIMEOUT_UPLOAD_MIDIA).put(
      `/v1/webserie/episode/additional/${id}`,
      body,
    );
  }

  static getWebserieQuestions({ token, id }) {
    return createInstance({ headers: { token } }).get(`/v1/webserie/${id}/questions`);
  }

  static getWebserieQuestion({ token, id }) {
    return createInstance({ headers: { token } }).get(`/v1/webserie/question/${id}`);
  }

  static putWebserieQuestion({ token, id, payload }) {
    return createInstance({ headers: { token } }).put(`/v1/webserie/question/${id}`, { ...payload });
  }

  static postWebserieQuestion({ token, webserieId, payload }) {
    return createInstance({ headers: { token } }).post(`/v1/webserie/${webserieId}/question`, { ...payload });
  }

  static deleteWebserieQuestion({ token, id }) {
    return createInstance({ headers: { token } }).delete(`/v1/webserie/question/${id}`);
  }

  // CareerContent
  static listCareerContents({ token, isNew, enabled, highlighted }) {
    let url = `/v1/careerContent/list/all`;
    return createInstance({ headers: { token } }).get(url, { params: { isNew, enabled, highlighted } });
  }

  static patchCareerContentsOrder({ token, payload }) {
    return createInstance({ headers: { token } }).patch('/v1/careerContent/order', payload);
  }

  static listCareerContentsWeb({ token, pageNumber, pageSize }) {
    let url = `/v1/careerContent/list/web?page=${pageNumber}&pageSize=${pageSize}`;
    return createInstance({ headers: { token } }).get(url);
  }

  static getCareerContent({ token, id }) {
    return createInstance({ headers: { token } }).get(`/v1/careerContent/get/${id}`);
  }

  static deleteCareerContent({ token, id }) {
    return createInstance({ headers: { token } }).delete(`/v1/careerContent/${id}`);
  }

  static putCareerContent({ token, id, payload }) {
    const body = new FormData();

    Object.keys(payload).map(key => body.append(key, payload[key]));

    return createInstance({ headers: { token, "Content-Type": "multipart/form-data" } }, undefined, TIMEOUT_UPLOAD_MIDIA).put(
      `/v1/careerContent/${id}`,
      body,
    );
  }

  static postCareerContent({ token, payload }) {
    const body = new FormData();

    Object.keys(payload).map(key => body.append(key, payload[key]));

    return createInstance({ headers: { token, "Content-Type": "multipart/form-data" } }, undefined, TIMEOUT_UPLOAD_MIDIA).post(
      '/v1/careerContent',
      body,
    );
  }

  static deleteCareerContentAdditional({ token, id }) {
    return createInstance({ headers: { token } }).delete(`/v1/careerContent/additional/${id}`);
  }

  static postCareerContentAdditional({ token, careerContentId, payload }) {
    const body = new FormData();

    Object.keys(payload).map(key => body.append(key, payload[key]));

    return createInstance({ headers: { token, "Content-Type": "multipart/form-data" } }, undefined, TIMEOUT_UPLOAD_MIDIA).post(
      `/v1/careerContent/${careerContentId}/additional`,
      body,
    );
  }

  static putCareerContentAdditional({ token, id, payload }) {
    const body = new FormData();

    Object.keys(payload).map(key => body.append(key, payload[key]));

    return createInstance({ headers: { token, "Content-Type": "multipart/form-data" } }, undefined, TIMEOUT_UPLOAD_MIDIA).put(
      `/v1/careerContent/additional/${id}`,
      body,
    );
  }

  static getCareerContentAdditional({ token, id }) {
    return createInstance({ headers: { token } }).get(`/v1/careerContent/additional/get/${id}`);
  }

  // Companies
  static listCompanies({ token, search, enabled, pageNumber, pageSize }) {
    let url = `/v1/company/list?page=${pageNumber}&pageSize=${pageSize}`;
    if (!isNullOrEmpty(enabled)) {
      url += `&enabled=${enabled}`;
    }
    if (!isNullOrEmpty(search)) {
      url += `&search=${search}`;
    }
    return createInstance({ headers: { token } }).get(url);
  }

  static getCompany({ token, id }) {
    return createInstance({ headers: { token } }).get(`/v1/company/get/${id}`);
  }

  static putCompany({ token, id, payload }) {
    return createInstance({ headers: { token } }).put(
      `/v1/company/${id}`,
      payload,
    );
  }

  static postCompany({ token, payload }) {
    return createInstance({ headers: { token } }).post(
      '/v1/company',
      payload,
    );
  }
  
  static deleteCompany({ token, id }) {
    return createInstance({ headers: { token } }).delete(`/v1/company/${id}`);
  }

  // Dashboard
  static getDashboardCounters({ token }) {
    return createInstance({ headers: { token } }).get('/v1/dashboard/counters');
  }

  // Report
  static getAnalyticsCompany({ token, companyId, startDate, endDate }) {
    const timeout = 60000;
    return createInstance({ headers: { token } }, 'blob', timeout).get(`/v1/report/analytics/${companyId}?startDate=${startDate}&endDate=${endDate}`);
  }

  static getUserProgressCompany({ token, companyId }) {
    const timeout = 120000;
    return createInstance({ headers: { token } }, 'blob', timeout).get(`/v1/report/user-progress/${companyId}`);
  }

  static getMarketingLeads({ token }) {
    const timeout = 120000;
    return createInstance({ headers: { token } }, 'blob', timeout).get(`/v1/report/marketing-leads`);
  }

  // Certificates
  static listCertificate({ token, searchText, pageNumber, pageSize }) {
    let url = `/v1/webserie/certificate/list?page=${pageNumber}&pageSize=${pageSize}`;
    if (!isNullOrEmpty(searchText)) {
      url += `&search=${searchText}`;
    }
    return createInstance({ headers: { token } }).get(url);
  }
}

export default APIService;
