import { combineReducers } from 'redux';
import AppReducer from './app';
import UserReducer from './user';
import JobsReducer from './jobs';

export default combineReducers({
  AppReducer,
  UserReducer,
  JobsReducer,
});
