export const Types = {
  FETCH_SECTORS: 'COMBOS/FETCH_SECTORS',
  SET_COMPANY_SECTORS: 'COMBOS/SET_COMPANY_SECTORS',
  FETCH_COMPANY_SIZES: 'COMBOS/FETCH_COMPANY_SIZES',
  SET_COMPANY_SIZES: 'COMBOS/SET_COMPANY_SIZES',
  FETCH_AREAS: 'COMBOS/FETCH_AREAS',
  SET_AREAS: 'COMBOS/SET_AREAS',
  FETCH_LEVELS: 'COMBOS/FETCH_LEVELS',
  SET_LEVELS: 'COMBOS/SET_LEVELS',
  FETCH_COURSE_TYPES: 'COMBOS/FETCH_COURSE_TYPES',
  SET_COURSE_TYPES: 'COMBOS/SET_COURSE_TYPES',
  FETCH_COURSE_STATUS: 'COMBOS/FETCH_COURSE_STATUS',
  SET_COURSE_STATUS: 'COMBOS/SET_COURSE_STATUS',
  FETCH_JOB_INTEGRATIONS: 'COMBOS/FETCH_JOB_INTEGRATIONS',
  SET_JOB_INTEGRATIONS: 'COMBOS/SET_JOB_INTEGRATIONS',
};

const initialState = {
  companySectors: [],
  companySizes: [],
  areas: [],
  levels: [],
  courseTypes: [],
  courseStatus: [],
  jobIntegrations: [],
};

export const getCompanySectors = () => ({
  type: Types.FETCH_SECTORS,
});

export const setCompanySectors = (companySectors) => ({
  type: Types.SET_COMPANY_SECTORS,
  companySectors,
});

export const getCompanySizes = () => ({
  type: Types.FETCH_COMPANY_SIZES,
});

export const setCompanySizes = (companySizes) => ({
  type: Types.SET_COMPANY_SIZES,
  companySizes,
});

export const getJobAreas = () => ({
  type: Types.FETCH_AREAS,
});

export const setJobAreas = (areas) => ({
  type: Types.SET_AREAS,
  areas,
});

export const getJobLevels = () => ({
  type: Types.FETCH_LEVELS,
});

export const setJobLevels = (levels) => ({
  type: Types.SET_LEVELS,
  levels,
});

export const getCourseTypes = () => ({
  type: Types.FETCH_COURSE_TYPES,
});

export const setCourseTypes = (courseTypes) => ({
  type: Types.SET_COURSE_TYPES,
  courseTypes,
});

export const getCourseStatus = () => ({
  type: Types.FETCH_COURSE_STATUS,
});

export const setCourseStatus = (courseStatus) => ({
  type: Types.SET_COURSE_STATUS,
  courseStatus,
});

export const getJobIntegrations = (token) => ({
  type: Types.FETCH_JOB_INTEGRATIONS,
  token,
});

export const setJobIntegrations = (jobIntegrations) => ({
  type: Types.SET_JOB_INTEGRATIONS,
  jobIntegrations,
});

const JobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_COMPANY_SECTORS:
      return { ...state, companySectors: action.companySectors };
    case Types.SET_COMPANY_SIZES:
      return { ...state, companySizes: action.companySizes };
    case Types.SET_AREAS:
      return { ...state, areas: action.areas };
    case Types.SET_LEVELS:
      return { ...state, levels: action.levels };
    case Types.SET_COURSE_TYPES:
      return { ...state, courseTypes: action.courseTypes };
    case Types.SET_COURSE_STATUS:
      return { ...state, courseStatus: action.courseStatus };
    case Types.SET_JOB_INTEGRATIONS:
      return { ...state, jobIntegrations: action.jobIntegrations };
    default:
      return state;
  }
};

export default JobsReducer;
